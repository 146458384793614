import "./Footer.scss";
import { images } from "../../utils/images";
import { FaLinkedinIn } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <section className="footer">
        <div className="content_wrap">
          <div className="footer_grid">
            <div className="company_info">
              <div className="img_wrap">
                <a href="/">
                  <img src={images.finneva} alt="logo" />
                </a>
              </div>
            </div>

            <div className="link_col">
              <p className="title">Useful Links</p>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/contactus">Contact Us</NavLink>
            </div>

            <div className="link_col">
              <p className="title">Our Solutions</p>
              <NavLink to="/solutions/businesses">For Businesses</NavLink>
              <NavLink to="/solutions/institution">
                For Financial Institutions
              </NavLink>
            </div>

            <div className="link_col">
              <p className="title">Stay Connected</p>
              <div className="social_wrap">
                <a
                  href="https://www.linkedin.com/company/finneva/"
                  target="_blank"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
          {/* <div className="bottom_line">
            <div className="left"></div>
            <div className="right">
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink to="/cookie-policy">Cookie Policy</NavLink>
            </div>
          </div> */}
        </div>
      </section>
    </footer>
  );
};

export default Footer;
